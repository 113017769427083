/* eslint-disable global-require */
import { compose, configureStore } from '@reduxjs/toolkit';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
// importing reducers
import rootReducer from './reducers/index';

const sagaMiddleware = createSagaMiddleware();

// const configureStore = (initialState) => {
//   const store = createStore(
//     rootReducer,
//     initialState,
//     compose(
//       applyMiddleware(
//         sagaMiddleware,
//       ),
//       // DevTools.instrument()
//       window.devToolsExtension ? window.devToolsExtension() : (f) => f,
//     ),
//   );

//   sagaMiddleware.run(rootSaga);

//   return { store };
// };
// export default configureStore;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  // DevTools.instrument()
  devTools: window.devToolsExtension ? window.devToolsExtension() : (f) => f,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/index', () => {
    const newRootReducer = require('./reducers/index').default;
    store.replaceReducer(newRootReducer);
  });
}

const persistor = persistStore(store);

export { persistor, store as default };
