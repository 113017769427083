import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Drawer } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import ListItems from './ListItems';
// importing styles
import useStyles from './headerStyles';
import centerLogo from '../../assets/centerLogo.png';

const LiveWorkoutHeader = ({ selectedProgram }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <AppBar position="static" className={classes.header2}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon style={{ color: 'rgb(164 197 29)', fontSize: 40 }} />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}
          style={{
            width: '550px',
            padding: '0px 0px 20px',
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => handleDrawerToggle(false)}>
              <ChevronLeftIcon style={{ color: '#000', fontSize: 40 }} />
            </IconButton>
          </div>
          <ListItems />
        </Drawer>
        <SelectedProgram selectedProgram={selectedProgram} />
        <a className={classes.logoImg} href="/">
          <img className={classes.logo} src={centerLogo} alt="logo" />
        </a>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <CloseIcon
            style={{ color: '#fff', fontSize: 40 }}
            onClick={() => {
              history.push({ pathname: '/' });
            }}
          />
        </IconButton>
        {/* <Button color="inherit">Login</Button> */}
      </Toolbar>
    </AppBar>
  );
};

const SelectedProgram = ({ selectedProgram }) => {
  const classes = useStyles();
  return (
    <div className={classes.FloatingHeaderContainer}>
      <img
        className={classes.FloatingLogo}
        src={`https://studiogym.com/upload/${selectedProgram.photo}`}
        alt="test-logo"
      />
      <p className={classes.FloatingLogoText}>{selectedProgram.description}</p>
    </div>
  );
};

SelectedProgram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedProgram: PropTypes.object.isRequired,
};

LiveWorkoutHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedProgram: PropTypes.object.isRequired,
};

export default LiveWorkoutHeader;
