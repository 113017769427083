import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'calc(var(--vh, 1vh) * 5)',
    marginBottom: 'calc(var(--vh, 1vh) * 5)',

  },
  paperStyle: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'row',
    // minHeight: 'calc(var(--vh, 1vh) * 15)',
    padding: '1rem',
  },
  iconContainer: {
    display: 'flex',
    flex: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    // flex: 0.8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '1rem',
    fontSize: '1rem',
    letterSpacing: '1px',
  },
  root1: {
    display: 'flex',
    flex: 1,

    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'calc(var(--vh, 1vh) * 5)',
    marginBottom: 'calc(var(--vh, 1vh) * 5)',
    backgroundColor: 'white',
    flexDirection: 'row',
  },

  paper: {
    display: 'flex',
    flex: 1,

    backgroundColor: 'white',
    minHeight: 'calc(var(--vh, 1vh) * 15)',
    padding: 'calc(var(--vh, 1vh) * 1)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    display: 'flex',
    flex: 0.15,
    width: '95%',
    flexDirection: 'row',
    paddingTop: 'calc(var(--vh, 1vh) * 4)',
    paddingBottom: '1rem',
    cursor: 'pointer',
  },
  time: {
    display: 'flex',
    flex: 0.15,
    width: '95%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    marginBottom: '1rem',
    paddingBottomt: 'calc(var(--vh, 1vh) * 2)',
  },
  timepicker: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
  actions: {
    display: 'flex',
    flex: 0.3,
    width: '95%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

  },
  invite: {
    display: 'flex',
    flex: 0.4,
    paddingTop: '1rem',

    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  field: {

  },
  adornment: {
    padding: '5px',
  },
  button: {
    padding: '13px',
  },
  button1: {
    padding: '7px',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
      textDecoration: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
      textDecoration: 'none',

    },
  },

}));
