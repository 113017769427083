import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  calendarContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    padding: '0 1rem',

  },
  calendarRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },

  calItemRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  calendarcellhead: {
    display: 'flex',
    flex: 0.17,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2px',
  },
  currentdaycell: {
    display: 'flex',
    flex: 0.17,
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '2px',
    background: 'white',
    height: '80px',
    borderWidth: 2,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  calendarcell: {
    display: 'flex',
    flex: 0.17,
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '4px',
    background: 'white',
    height: '80px',

  },
  MonthDiv: {
    marginBottom: '.5rem',
    marginTop: '-10px',
    display: 'flex',
    flex: 0.1,
    textTransform: 'uppercase',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    position: 'relative',

  },

  invisbleButton: {
    border: 'none',
    background: 'transparent',
    fontSize: '12px',
    outline: 'none !important',
    boxShadow: 'none !important',
    height: '100%',
    width: '100%',
    // display: 'flex',
    alingItems: 'flex-start',
    justifyContent: 'center',
  },
  iconContainer: {
    display: 'flex',
    paddingLeft: '.5rem',
    position: 'relative',
    zIndex: 1,
    // flex: 0.1,
  },
  firstEvent: {
    backgroundColor: '#57575a',
    width: '100%',
    padding: '.1rem .2rem',
    marginBottom: '.1rem',
    marginTop: '10px',
    color: '#fff',
    fontWeight: 'bolder',
  },
  secondEvent: {
    backgroundColor: '#57a29d',
    width: '100%',
    padding: '.1rem .2rem',
    marginBottom: '.1rem',
    color: '#fff',
    fontWeight: 'bolder',
  },
}));
