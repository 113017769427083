import React from 'react';
import { uniqueId } from 'lodash';
import { PropTypes } from 'prop-types';
import useStyles from '../../css/VideoChatStyles';

function ExcerciseCardHeader({
  index, _index, exercises, next,
}) {
  const classes = useStyles();
  const id = uniqueId();
  return (
    <div className={classes.ExcersiceCardHeader}>
      <span className={classes.slideCount}>
        {_index + 1}
      </span>
      <label
        className="containera"
        htmlFor={id}
      >
        <input
          type="checkbox"
          id={id}
          hidden
          disabled={index !== _index}
          onChange={() => {
            next(_index + 1, _index === exercises.length - 1);
          }}
        />
        <span className="checkmark" />
      </label>
    </div>
  );
}

ExcerciseCardHeader.propTypes = {
  index: PropTypes.number.isRequired,
  _index: PropTypes.number.isRequired,
  exercises: PropTypes.arrayOf(PropTypes.object).isRequired,
  next: PropTypes.func.isRequired,
};

export default ExcerciseCardHeader;
