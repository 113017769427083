import React from 'react';
import { PropTypes } from 'prop-types';
import useStyles from '../../css/VideoChatStyles';

const ExcersiceCardImage = ({ path }) => {
  const classes = useStyles();
  return (
    <div className={classes.excersiceCardImage}>
      <img
        src={`https://studiogym.com/upload/${path}`}
        alt="workout"
      />
    </div>
  );
};

ExcersiceCardImage.propTypes = {
  path: PropTypes.string.isRequired,
};

export default ExcersiceCardImage;
