import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 'calc(var(--vh, 1vh) * 5)',
  },

  paper: {
    display: 'flex',
    // flex: 0.9,
    flexDirection: 'column',
    width: '100%',
    background: 'white',
    alignItems: 'center',
    // justifyContent: 'center',
    minHeight: 'calc(var(--vh, 1vh) * 30)',
  },
  title: {
    display: 'flex',
    flex: 0.1,
    // width: '80%',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: '1rem',
    // borderBottomWidth: '1px',
    // borderColor: 'black',
    // borderBottomStyle: 'solid',
  },
  time: {
    display: 'flex',
    // flex: 0.1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    paddingTop: 'calc(var(--vh, 1vh) * 1)',
    paddingBottom: 'calc(var(--vh, 1vh) * 1)',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // minHeight: 'calc(var(--vh, 1vh) * 30)',

  },
  timepicker: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
  actions: {
    display: 'flex',
    flex: 0.3,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 'calc(var(--vh, 1vh) * 2)',

  },
  invite: {
    display: 'flex',
    flex: 0.4,
    paddingTop: 'calc(var(--vh, 1vh) * 2)',

    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  icons: {
    fontSize: '1.5rem',
  },
  livebutton: {
    width: '100%',
    background: '#231f20',
    color: '#a2c127',
  },
}));
