/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// importing UI
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Switch from 'react-switch';

// importing helpers

// importing icons
import DescriptionIcon from '@material-ui/icons/Description';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VideocamIcon from '@material-ui/icons/Videocam';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  selectDate,
  updateScheduleTime,
  updateLiveMode,
  deleteSchedule,
  sendInvitation,
  DeleteSchedule, setSchedule, EditSchedule,
} from '../Slice/ScheduleSlice';

import useStyles from '../css/AgendaCardStyles';
import copyToClipboard from '../../../utils/copyToClipboard';
import createAgendaAgain from './createAgendaAgain';
import singleProgram from '../../programs/screens/singleProgram';
import { chooseProgram } from '../../programs/Slice/ProgramSlice';
// import { date } from 'faker';

const AgendaCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line no-underscore-dangle
  console.log('props', props);
  const { schedule, program } = props;
  const {
    time,
    invited_members,
    day,
  } = schedule;

  const {
    description,
    photo,
    short_text,
  } = program;
  // eslint-disable-next-line no-underscore-dangle
  const id = schedule._id;
  const [t, setT] = React.useState(time !== undefined ? new Date(time) : new Date());
  // const [endTime, setEndTime] = React.useState(moment(etime));
  const [live, setLive] = React.useState(schedule.live);
  const [isModify, setIsModify] = React.useState(true);
  const [showCopyText, setShowCopyText] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [ProgramName, setProgramName] = React.useState('');
  const dispatch = useDispatch();
  const scheduleState = useSelector((state) => state.scheduler);

  const Delete = () => {
    dispatch(DeleteSchedule({
      id,
    }));
    // eslint-disable-next-line no-underscore-dangle
    dispatch(setSchedule(scheduleState.schedule.filter((_schedule) => _schedule._id !== id)));
  };

  const Modify = () => {
    console.log('schedule', schedule);
  };

  const dt = useSelector(selectDate);
  const dispatchTime = (tm) => {
    dispatch(updateScheduleTime({
      id,
      time: moment(tm).toISOString(),
    }));

    dispatch(EditSchedule({
      id,
      time: moment(tm).toISOString(),
    }));
  };

  const deleteWorkout = () => {
    console.log('1dd', id);
    dispatch(
      deleteSchedule({
        id,
      }),
    );
  };
  useEffect(() => {
    sessionStorage.removeItem('WorkoutId');
  }, []);
  useEffect(() => {
    setLive(schedule.live);
    window.addEventListener('touchmove', () => {
      const datePickers = document.getElementsByClassName('react-datepicker__input-container');
      Array.from(datePickers).forEach(((el) => el.childNodes[0].setAttribute('readOnly', true)));
    });
  }, [schedule, deleteSchedule]);

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        {isModify ? (
          <div
            style={{
              display: 'flex',
              flex: 0.2,
              padding: '10px',
              width: '100%',
            }}
          >
            <InsertDriveFileIcon className="icons" color="black" />
            <span
              style={{
                marginLeft: '15px',
                borderBottom: '1px solid',
                cursor: 'pointer',
              }}
              aria-hidden="true"
              onClick={() => setIsModify(false)}
            >
              Modifier
            </span>
          </div>
        ) : (
          <div
            style={{
              padding: '10px',
              position: 'relative',
              right: '-40%',
              display: 'inherit',
            }}
          >
            <div>
              {edit ? (
                <CloseIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => setEdit(!edit)} />
              ) : (
                <>
                  <EditIcon
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      Modify();
                      setIsModify(true);
                    }}
                  />
                  <DeleteIcon
                    color="secondary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      deleteWorkout();
                      Delete();
                    }}
                  />
                </>
              )}
            </div>

          </div>
        )}
        {edit ? (
          <>
            <div className={classes.title} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
              <div style={{
                display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
              }}
              >
                <TextField
                  InputLabelProps={{
                    style: {
                      color: 'black',
                      cursor: 'pointer',
                    },
                  }}
                  InputProps={{
                    style: {
                      color: 'black',
                      cursor: 'pointer',
                    },
                    startAdornment: (
                      <InputAdornment style={{ marginBottom: '10px', height: 'auto', display: 'block' }} position="start">
                        <DescriptionIcon className="icons" fontSize="large" color="black" />
                      </InputAdornment>
                    ),
                  }}
                  input={(
                    <Input
                      style={{
                        cursor: 'pointer',
                      }}
                      classes={classes.field}
                      onChange={(val) => { setProgramName(val); }}
                      value={ProgramName}
                    />
                  )}
                  onFocus={() => {
                    // deleteWorkout();
                    sessionStorage.setItem('WorkoutId', id);
                    history.push({
                      pathname: '/programs/',
                    });
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                  required
                  placeholder="Recherche de programme"
                  value={ProgramName}
                  fullWidth
                  onChange={(val) => { setProgramName(val); }}
                />
              </div>
            </div>
            <div className={classes.time} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
              <ScheduleIcon className="icons" fontSize="large" color="black" style={{ marginBottom: 10 }} />
              <TextField
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{ marginBottom: '20px', marginTop: '10px' }}
                      position="start"
                    />
                  ),
                }}
                // type="time"
                required
                placeholder="Heure"
                value="Heure"
                fullWidth
                onChange={(val) => { setProgramName(val); }}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.title}>
              <div
                style={{
                  paddingTop: '2vh',
                  paddingLeft: '1rem',
                }}
              >
                <img
                  src={`https://studiogym.com/upload/${photo}`}
                  alt="exercise"
                  style={{ height: '12vh', width: '12vh' }}
                />
              </div>
              <div
                style={{
                  padding: '10px',
                }}
              >
                <p>
                  <b>{description}</b>
                  <br />
                  Day
                  {' '}
                  {day}
                </p>
              </div>
            </div>
            <div className={classes.time}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '.5rem',
                }}
              >
                <ScheduleIcon className="icons" fontSize="large" color="black" />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >

                {/* <TimePicker
              allowClear={false}
              className={classes.timepicker}
              bordered
              inputReadOnly
              suffixIcon={<div />}
              onChange={(tt, timeString) => {
                dispatchTime(tt, timeString);
                setT(moment(tt));
              }}
              value={t !== null ? moment(t) : moment()}
              format="HH:mm"
            /> */}
                <DatePicker
                  className="timepicker-react"
                  selected={t}
                  onChange={(Date) => {
                    const d = moment(Date);
                    dispatchTime(d, d.format('HH:mm'));
                    setT(Date);
                    // setStartDate(Date)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
              </div>
            </div>
          </>
        )}
        <div className={classes.actions}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '0 1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 7,
              }}
            >
              <VideocamIcon className="icons" fontSize="large" color="black" />
            </div>
            <div
              style={{
                textAlign: 'left',
                flex: 1,
              }}
            >
              LIVE WORKOUT
            </div>
            <div>
              <Switch
                className={live ? 'colorLiveBtn' : 'removeColorLiveBtn'}
                onChange={() => {
                  dispatch(
                    updateLiveMode({
                      id,
                      date: moment(dt).format('DD/MM/YYYY'),
                      live: !live,
                    }),
                  );
                  dispatch(EditSchedule({
                    id,
                    live: !live,
                  }));
                  setLive(!live);
                }}
                checked={live}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: '0.5rem',
              marginLeft: '2.8rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              width: '90%',
              marginBottom: 'calc(var(--vh, 1vh) * 2)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 0.6,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  width: '100%',
                }}
                variant="outlined"
                className={live ? classes.livebutton : null}
                startIcon={<VideocamIcon fontSize="large" color="black" />}
                disabled={!live}
                onClick={() => {
                  dispatch(chooseProgram(program));
                  history.push({
                    pathname: `/liveWorkout/${id}`,
                  });
                }}
              >
                ACCES AU LIVE
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 0.3,
                height: '36px',
                margin: '.5rem',
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                style={{ padding: '7px', width: '100%' }}
                disabled={!live}
                onClick={() => {
                  copyToClipboard(`${window.location.origin}/liveWorkout/${id}`);
                  setShowCopyText(true);
                  setTimeout(() => setShowCopyText(false), 5000);
                }}
              >
                <ShareOutlinedIcon fontSize="medium" color="black" />
              </Button>
            </div>
          </div>
          <div>
            <p style={{ fontSize: 12, color: '#525252', fontWeight: 'bold' }}>{showCopyText && 'Link copied'}</p>
          </div>

          <div
            style={{
              display: 'flex',
              flex: 0.01,
              width: '95%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            }}
          />
        </div>
        <div className={classes.invite}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'start',
              width: '100%',
              padding: '0 1rem',
            }}
          >
            <div
              style={{
                marginRight: 7,
              }}
            >
              <SupervisorAccountIcon
                className="icons"
                fontSize="large"
                color="black"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 0.9,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                history.push({
                  pathname: '/inviteList',
                  state: {
                    schedulePhoto: photo,
                    scheduleId: id,
                  },
                });
              }}
            >
              Ajouter invités
            </div>
          </div>
          <div
            style={{
              width: '75%',
              paddingTop: 'calc(var(--vh, 1vh) * 2)',
              paddingBottom: 'calc(var(--vh, 1vh) * 2)',
            }}
          >
            <Button
              style={{ width: '100%' }}
              variant="outlined"
              color="inherit"
              className={classes.button1}
              startIcon={<EmailOutlinedIcon fontSize="large" color="black" />}
              disabled={!live}
              onClick={() => {
                dispatch(
                  sendInvitation({
                    scheduleId: id,
                    invited_members,
                    liveWorkoutLink: 'http://app-test.studiogym.com/liveWorkout',
                    startDate: schedule.date,
                    title: description,
                    description: short_text,
                    day,
                    photoLink: photo,
                  }),
                );
              }}
            >
              ENVOYER L’INVITATION
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

AgendaCard.propTypes = {
  program: PropTypes.shape({
    description: PropTypes.string.isRequired,
    _id: PropTypes.string,
    short_text: PropTypes.string,
    photo: PropTypes.string,
    DayWiseSelection: PropTypes.shape({
      index: PropTypes.number,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.array,
    }),
    Image: PropTypes.string,
  }),
  schedule: PropTypes.shape({
    date: PropTypes.string,
    _id: PropTypes.string.isRequired,
    day: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    invited_members: PropTypes.array.isRequired,
    time: PropTypes.string,
    etime: PropTypes.string,
    live: PropTypes.bool,
  }),
};

export default AgendaCard;
