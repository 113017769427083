import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';

// importing helpers
import AgendaCard from '../helpers/AgendaCard';
import CreateAgenda from '../helpers/createAgenda';

function Agenda(props) {
  const { date } = props;

  const { schedule } = useSelector((state) => state.scheduler);
  const { programs } = useSelector((state) => state.programs);

  const scheduleToDate = schedule.filter((e) => {
    const splitDate = e.date.split('T')[0];
    const [year, month, day] = splitDate.split('-');
    const finalDate = day ? new Date(year, month - 1, day) : e.date;
    return moment(finalDate).format('DD/MM/YYYY') === date;
  });

  localStorage.setItem('SELECTED_DATE', date.split('/').reverse().join('-'));

  const renderAgenda = () => {
    // console.log('data11', data);
    console.log('RENDERING', schedule);

    return scheduleToDate.map((dt) => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const program = programs.find((_program) => _program._id === dt.program);
      if (program) {
      // eslint-disable-next-line no-underscore-dangle
        return <AgendaCard schedule={dt} program={program} key={dt._id + Date.now()} />;
      }

      return <CreateAgenda date={date} />;
    });
  };

  if (scheduleToDate.length === 0) {
    return <CreateAgenda date={date} />;
  }

  return (
    <div className="pd-l" style={{ width: '100%', padding: '0 1.5rem' }}>
      {renderAgenda()}
    </div>
  );
}

export default Agenda;

Agenda.propTypes = {
  date: PropTypes.string,
};
