export default {
  selectedProgram: null,
  programs: [
    // {
    //   name: 'Bye Bye Belly Jelly',
    //   id: '100',
    //   Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
    //   DayWise: {
    //     Day1: {
    //       Name: 'test1',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day2: {
    //       Name: 'test2',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day3: {
    //       Name: 'test3',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //   },
    // },
    // {
    //   name: 'Hitt 40 ans3 jour par semaines',
    //   id: '101',
    //   Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
    //   DayWise: {
    //     Day1: {
    //       Name: 'test1',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day2: {
    //       Name: 'test2',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day3: {
    //       Name: 'test3',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //   },
    // },
    // {
    //   name: 'Warm-up Start',
    //   id: '102',
    //   Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
    //   DayWise: {
    //     Day1: {
    //       Name: 'test1',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day2: {
    //       Name: 'test2',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day3: {
    //       Name: 'test3',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //   },
    // },
    // {
    //   name: 'Bye Bye Belly jelly',
    //   id: '103',
    //   Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
    //   DayWise: {
    //     Day1: {
    //       Name: 'test1',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day2: {
    //       Name: 'test2',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day3: {
    //       Name: 'test3',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //   },
    // },
    // {
    //   name: 'Bye Bye Belly jelly',
    //   id: '104',
    //   Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
    //   DayWise: {
    //     Day1: {
    //       Name: 'test1',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day2: {
    //       Name: 'test2',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //     Day3: {
    //       Name: 'test3',
    //       Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
    //     },
    //   },
    // },
  ],
};
