import { makeStyles, useTheme } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoImg: {
    display: 'flex',
    textAlign: 'center',
    margin: '0 auto',
    marginLeft: '-1.5rem',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    maxHeight: '40px',
    width: '100px',
    display: 'block',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
  },
  header: {
    background: '#a4c408',
    justifyContent: 'space-between',
  },
  Toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  header2: {
    background: '#181818',
    zIndex: 100,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // display: 'flex',
    // flex: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  drawerHeader: {
    background: '#a4c408',
  },
  FloatingHeaderContainer: {
    position: 'absolute',
    top: '10px',
    left: '100px',
    zIndex: 200,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    columnGap: '10px',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      alignItems: 'center',
      flex: 1,
    },
  },
  FloatingLogo: {
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '50px',
    },
  },
  FloatingLogoText: {
    color: '#a2c127',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}));
