import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    background: '#d8e6dc !important',
  },
  navBar: {
    display: 'flex',
    // flex: 0.1,
    width: '90%',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    // paddingBottom: '.5rem',
    marginTop: '70px',
    padding: '1rem 0',
  },
  navBar1: {
    display: 'flex',
    flex: 0.2,
    width: '90%',
    flexDirection: 'row',

  },
  body: {
    display: 'block',
    // flex: 0.9,
    width: '90%',
    flexDirection: 'column',
    overflowY: 'auto',
    marginBottom: '20px',
    overflowX: 'hidden',

  },
  program: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  imageContainer: {
    display: 'flex',
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  workoutimage: {
    height: 'calc(var(--vh, 1vh) * 17)',
    width: 'calc(var(--vh, 1vh) * 17)',

  },
  descriptionContainer: {
    display: 'flex',
    // flex: 0.7,
    alignItems: 'center',
    // justifyContent: 'flex-start',
    paddingLeft: '20px',
    fontSize: '1.2rem',
    textAlign: 'left',
    width: '100%',

    // fontWeight: 'bold',
  },
  dialogRoot: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.42)',
    background: '#d8e6dc !important',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  programDetail: {
    display: 'flex',
    marginTop: 'calc(var(--vh, 1vh) * 2)',
    width: '90%',
    // flex: 0.2,
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  selector: {
    display: 'flex',
    // flex: 0.15,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // marginBottom: 20,
  },
  selectBox: {
    background: 'white',
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '50%',
    border: 'none',
    outline: 'none',
    borderBottom: 'none !important',
  },
  programImage: {
    display: 'flex',
    // flex: 0.55,
    alignItems: 'center',
    width: '100%',
    padding: '0 1rem 0 0',
    flexDirection: 'column',
    marginTop: '10px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  workoutImage: {
    height: 'calc(var(--vh, 1vh) * 40)',
    width: 'calc(var(--vh, 1vh) * 40)',
    maxHeight: 150,
  },
  singleExerciseDiv: {
    display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  singleExerciseDivEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '250px',
    marginTop: '45%',
  },
  singleExerciseDivText: {
    marginTop: 10,
    // transform: 'translateY(-50%)',
  },
}));
