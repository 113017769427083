import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router-dom';

// importing UI and resources
import EventIcon from '@material-ui/icons/Event';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Grid from '@material-ui/core/Grid';

// importing icons
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VideocamIcon from '@material-ui/icons/Videocam';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import {
  selectDate,
  selectSchedule,
  addSchedule,
  setDate,
  setYear,
  fetchSchedules,
  setSchedule,
  setLoading,
  setError,
  CreateSchedule,
} from '../Slice/ScheduleSlice';

// importing styles
import 'antd/dist/antd.css';
import useStyles from '../css/schedulerstyles';

// importing components
import Agenda from './Agenda';
import Calendar from '../../../UI/calendar/calendar';
import CreateAgendaAgain from '../helpers/createAgendaAgain';
import { fetchPrograms, setPrograms } from '../../programs/Slice/ProgramSlice';

function Scheduler(props) {
  const [createMode, setMode] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const date = useSelector((state) => state.scheduler.currentDate);
  const { schedule, loading, error } = useSelector((state) => state.scheduler);
  const [secondSchedule, setSecondSchedule] = useState(false);
  const [loadMore, setLoadMore] = React.useState(false);
  const [Page, setPage] = React.useState(1);
  const [searchData, setSearchData] = React.useState('');

  const fetchAllSchedules = async (userInfo) => {
    dispatch(setLoading(true));
    await dispatch(fetchSchedules(userInfo))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        dispatch(setLoading(false));
        if (schedule.length <= res.data.length) {
          dispatch(setSchedule(res.data));
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        dispatch(setLoading(false));
        dispatch(setError(rejectedValueOrSerializedError));
      });
  };

  const loadRendering = async () => {
    const userInfo = { id: '100', startDate: moment(date).clone().startOf('month').format('DD/MM/YYYY'), endDate: moment(date).clone().endOf('month').format('DD/MM/YYYY') };
    fetchAllSchedules(userInfo);
  };

  const fetchAsyncPrograms = async (pageData) => {
    console.log('page', pageData);
    await dispatch(fetchPrograms(pageData))
      .then(unwrapResult)
      .then((res) => {
        // console.log('ress', ...res);
        dispatch(setPrograms([...res]));
        setLoadMore(false);
        return loadRendering();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    if (loadMore) {
      fetchAsyncPrograms({ page: Page + 1, limit: 10, search: searchData });
      setPage((prevState) => ({ Page: prevState.Page + 1 }));
      // fetchAsyncPrograms()
    }
  }, [loadMore]);

  const [limit, setLimit] = useState(0);
  useEffect(() => {
    setLoading(true);
    setSecondSchedule(false);
    setLimit(0);
    schedule.forEach((Oneschedule) => {
      if (Oneschedule.date === moment(date).format('DD/MM/YYYY')) {
        setLimit((l) => l + 1);
      }
    });
    setLoading(false);
  }, [date]);

  useEffect(() => {
    setLoading(true);
    setSecondSchedule(false);
    setLimit(0);
    schedule.forEach((Oneschedule) => {
      if (Oneschedule.date === moment(date).format('DD/MM/YYYY')) {
        setLimit((l) => l + 1);
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAsyncPrograms({ page: 1, limit: 10, search: searchData });
  }, []);

  const changeDate = (dt) => {
    dispatch(setDate(dt));
  };
  const changeYear = (yr) => {
    dispatch(setYear(yr));
  };

  const renderTrainings = () => <Agenda date={moment(date).format('DD/MM/YYYY')} />;

  return (
    <div className={classes.body}>
      <div className={`pd-l ${classes.header}`}>
        <div className={classes.headerIcon}>
          <EventIcon style={{ color: 'black' }} />
        </div>
        <div className={classes.text}>
          ÉVÈNEMENTS
        </div>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
          <div className={classes.calendar}>
            <Calendar
              schedules={schedule}
              currentDate={date}
              setMonth={changeDate}
              setYear={changeYear}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.agenda}>
            <div className={`pd-l ${classes.agendaActions}`}>
              <div className={classes.dateSection}>
                <u style={{ borderBottom: '5px solid', textDecoration: 'none' }}>
                  {moment(date).format('DD/MM/YYYY')}
                </u>
              </div>
              <div className={classes.actionSection}>
                <AddBoxOutlinedIcon fontSize="large" color="black" className={limit > 1 ? classes.dnone : null} disabled={limit > 1} onClick={() => { setSecondSchedule(true); }} />
                {/* <AddBoxOutlinedIcon  onClick={() => { createSchedule(); }} /> */}
              </div>
            </div>
            <div className={classes.trainings}>
              {secondSchedule ? <CreateAgendaAgain /> : renderTrainings()}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Scheduler;
