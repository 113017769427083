import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// importing UI Components
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Switch from 'react-switch';
import moment from 'moment';

// importing icons
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VideocamIcon from '@material-ui/icons/Videocam';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

// importing styles
import useStyles from '../css/AgendaStyles';
import 'antd/dist/antd.css';

function createAgenda(props) {
  const classes = useStyles();
  const [createMode, setMode] = React.useState(false);
  const [ProgramName, setProgramName] = React.useState('');
  const [time, setTime] = React.useState(new Date());
  const [live, setLive] = React.useState(false);
  const history = useHistory();
  const { date } = props;
  useEffect(() => {
    setMode(false);
  }, [props.date]);

  console.log(date);
  console.log('_O_', createMode);

  if (createMode) {
    return (
      <div className={classes.root1}>
        <div className={classes.paper}>
          <div className={classes.title}>
            <div style={{
              display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
            }}
            >
              <TextField
                InputLabelProps={{
                  style: {
                    color: 'black',
                    cursor: 'pointer',
                  },
                }}
                InputProps={{
                  style: {
                    color: 'black',
                    cursor: 'pointer',
                  },
                  startAdornment: (
                    <InputAdornment style={{ marginBottom: '10px', height: 'auto', display: 'block' }} position="start">
                      <DescriptionIcon className="icons" fontSize="large" color="black" />
                    </InputAdornment>
                  ),
                }}
                input={(
                  <Input
                    style={{
                      cursor: 'pointer',
                    }}
                    classes={classes.field}
                    onChange={(val) => { setProgramName(val); }}
                    value={ProgramName}
                  />
                )}
                onFocus={() => {
                  history.push({
                    pathname: '/programs',
                  });
                }}
                style={{
                  cursor: 'pointer',
                }}
                required
                placeholder="Recherche de programme"
                value={ProgramName}
                fullWidth
                onChange={(val) => { setProgramName(val); }}
              />
            </div>
          </div>
          <div className={classes.time}>
            <ScheduleIcon className="icons" fontSize="large" color="black" style={{ marginBottom: 10 }} />
            <TextField
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ marginBottom: '20px', marginTop: '10px' }}
                    position="start"
                  />
                ),
              }}
              // type="time"
              required
              placeholder="Heure"
              value="Heure"
              fullWidth
              onChange={(val) => { setProgramName(val); }}
            />
          </div>
          <div className={classes.actions}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0',
            }}
            >
              <div style={{
                display: 'flex', alignItems: 'center', marginRight: 7,
              }}
              >
                <VideocamIcon className="icons" fontSize="large" color="black" />
              </div>
              <div style={{
                textAlign: 'left', flex: 1,
              }}
              >
                LIVE WORKOUT
              </div>
              <div>
                <Switch disabled onChange={() => { setLive(!live); }} checked={live} />
              </div>
            </div>

            <div style={{
              marginTop: '0.5rem', marginLeft: '2.8rem', display: 'flex', alignItems: 'center', flexDirection: 'row', width: '90%', marginBottom: 'calc(var(--vh, 1vh) * 2)',
            }}
            >
              <div style={{
                display: 'flex', flex: 0.6, alignItems: 'center', justifyContent: 'center',
              }}
              >
                <Button
                  disabled
                  style={{ width: '100%' }}
                  color="#000"
                  variant="outlined"
                  className={classes.button1}
                  startIcon={<VideocamIcon fontSize="large" color="black" />}
                >
                  ACCES AU LIVE
                </Button>
              </div>
              <div style={{
                display: 'flex', flex: 0.3, height: '36px', margin: '.5rem',
              }}
              >
                <Button
                  disabled
                  variant="outlined"
                  color="#000"
                  style={{ padding: '7px', width: '100%' }}
                >
                  <ShareOutlinedIcon fontSize="medium" color="black" />
                </Button>
              </div>
            </div>

            <div style={{
              display: 'flex', flex: 0.01, width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            }}
            />
          </div>
          <div className={classes.invite}>
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', width: '95%',
            }}
            >
              <div style={{
                marginRight: 7,
              }}
              >
                <SupervisorAccountIcon className="icons" fontSize="large" color="black" />
              </div>
              <div style={{
                display: 'flex', flex: 0.9, alignItems: 'center',
              }}
              >
                Ajouter invités
              </div>
            </div>
            <div style={{
              width: '75%', paddingTop: 'calc(var(--vh, 1vh) * 2)', paddingBottom: 'calc(var(--vh, 1vh) * 2)',

            }}
            >
              <Button
                disabled
                style={{ width: '100%' }}
                variant="outlined"
                color="#000"
                className={classes.button1}
                startIcon={<EmailOutlinedIcon fontSize="large" color="black" />}
              >
                ENVOYER L’INVITATION
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.paperStyle}>
        <div className={classes.iconContainer}>
          <AddIcon color="black" fontSize="large" onClick={() => { setMode(!createMode); }} />
        </div>
        <div className={classes.textContainer}>
          AJOUTER ENTRAINEMENT
        </div>
      </div>
    </div>
  );
}

export default createAgenda;
