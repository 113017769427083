import React from 'react';
import { PropTypes } from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import useStyles from '../../css/VideoChatStyles';

const ExcersiceCardVideo = ({ videoPath }) => {
  const classes = useStyles();
  return (
    <div data-vjs-player>
      <video className={classes.excersiceCardVideo} muted loop autoPlay playsInline type="video/mp4">
        <source
          src={`https://studiogym.com/upload/${videoPath}`}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

ExcersiceCardVideo.propTypes = {
  videoPath: PropTypes.string.isRequired,
};

export default ExcersiceCardVideo;
