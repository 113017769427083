import React, {
  useRef, useState,
} from 'react';
import { uniqueId } from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '@material-ui/core';
import useStyles from '../../css/VideoChatStyles';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExcerciseCardHeader from './ExcerciseCardHeader';
import ExcersiceCardVideo from './ExcersiceCardVideo';
import ExcersiceCardImage from './ExcersiceCardImage';

// eslint-disable-next-line react/prop-types
const ExcersiceCard = ({ passedExercise = undefined, exercises = [] }) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [index, setIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    rows: 1,
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    draggable: false,
    touchMove: false,
    className: classes.slider,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1.25,
        },
      },
    ],
  };

  const next = (current, isFinished) => {
    setIndex(current);
    sliderRef.current.slickGoTo(current);
    passedExercise(isFinished ? 100 : current + 1);
  };
  return (
    <Slider ref={sliderRef} {...settings}>
      {exercises.map((e, _index) => (
        <div className={classes.ExcersiceCardWrapper} key={uniqueId()}>
          <div
            className={`
              ${classes.ExcersiceCardContainer}
              ${index !== _index ? classes.ExcersiceCardContainerHidden : ''}
            `}
          >
            <ExcerciseCardHeader
              index={index}
              _index={_index}
              exercises={exercises}
              next={next}
            />

            <div className={classes.excersiceCardView}>
              {e.exercise_items.map(({ exercise }) => (
                (exercise?.video !== undefined && index === _index ? (
                  <ExcersiceCardVideo
                    videoPath={exercise.video}
                    key={uniqueId()}
                  />
                ) : (
                  <ExcersiceCardImage
                    path={exercise.other_path || ''}
                    key={uniqueId()}
                  />
                ))
              ))}
            </div>
            <ExcersiceAbout />
          </div>
        </div>
      ))}
      {/* do not remove this divs, they are important for slider to not stack */}
      <div />
      <div />
    </Slider>
  );
};

const ExcersiceAbout = () => {
  const classes = useStyles();
  return (
    <div className={classes.excersiceCardDescription}>
      <p>
        {/* {JSON.stringify(e.exercise_items[0])} */}
        Free Hand Half Squat
      </p>
      <p>
        {/* {JSON.stringify(e.exercise_items[0])} */}
        Series 2 | Repetitons 5
      </p>
      <IconButton className={classes.aboutButton}>
        <InfoOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default ExcersiceCard;
