import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { Drawer } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItems from './ListItems';
import LiveWorkoutHeader from './LiveWorkoutHeader';
// importing styles
import useStyles from './headerStyles';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function Header(props) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (location.pathname.indexOf('liveWorkout') >= 0) {
    return null;
  }
  return (
    <HideOnScroll {...props}>
      <AppBar position="static" className={classes.header}>
        <Toolbar className="pd-l">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon style={{ color: '#000', fontSize: 40 }} />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="left"
            open={open}
            onClose={handleDrawerToggle}
            style={{
              width: '550px',
              padding: '0px 0px 20px',
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={() => handleDrawerToggle(false)}>
                <ChevronLeftIcon style={{ color: '#000', fontSize: 40 }} />
              </IconButton>
            </div>
            <ListItems />
          </Drawer>
          <a className={classes.logoImg} href="/"><img className={classes.logo} src="https://studiogym.com/bundles/studiogymapp/img/logo-en.svg?v=20200606-67b5987a" alt="logo" /></a>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
