/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useLocation } from 'react-router-dom';
// importing icons and material ui components
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import baseUrl from '../../../config/baseUrl';

import useStyles from '../css/InviteList';
import {
  updateScheduleInvitees,
  deleteScheduleInvitees,
} from '../../Scehduler/Slice/ScheduleSlice';

const filter = createFilterOptions();

function InvitedList(props) {
  const [input, setInput] = useState('');
  const [data, setData] = useState('');
  const [email, setEmail] = useState('');
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState('');
  const [emailName, setEmailName] = React.useState('Enter Email');
  const handleClose = () => {
    setDialogValue('');

    toggleOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setEmail(dialogValue);

    handleClose();
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get(`${baseUrl}/invite/searchUserName?firstname=${input}`, config)
      .then((response) => {
        setData(response.data.body);
      });
  }, []);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const allSchedules = useSelector((state) => state.scheduler.schedule);
  const location = useLocation();
  const { schedulePhoto, scheduleId } = location.state;
  // eslint-disable-next-line no-underscore-dangle
  const currentSchedule = allSchedules.find((element) => element._id === scheduleId);
  const renderInviteList = () => currentSchedule.invitees.map((value, index) => (

    <div
      id={index.toString()}
      style={{
        borderBottom: '1px solid',
        outline: 'none',
        background: 'transparent',
        margin: '10px 0',
      }}
      className={classes.invitemember}
    >
      <ClearIcon
        fontSize="large"
        onClick={() => {
          dispatch(
            deleteScheduleInvitees({
              scheduleId,
              deleteIndex: index,
            }),
          );
        }}
      />
      <div id={index.toString()} className={classes.invitemember}>
        <div className={classes.imageContainer}>
          <img
            src={`https://studiogym.com/upload/${schedulePhoto}`}
            alt="profile"
            className={classes.profileImage}
          />
        </div>
        <div className={classes.invitationName}>{value}</div>
      </div>
    </div>
  ));
  return (
    <div className={classes.root}>
      <LinearProgress style={{ width: '100%', opacity: 0 }} />

      <div className={classes.navBar}>
        <div>
          <ArrowBackIosIcon
            color="black"
            fontSize="large"
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <div
          style={{
            fontSize: '1.3rem',
            color: 'rgb(32 34 33)',
            width: '100%',
          }}
        >
          <Autocomplete
            // value={email}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setTimeout(() => {
                  setEmail(newValue);
                });
              } else if (newValue && newValue.inputValue) {
                console.log('onChange', newValue.inputValue);
                setEmail(newValue.inputValue);
              } else {
                setEmail(newValue);
              }
            }}
            filterOptions={(options, params) => {
              if (params.inputValue.length < 3) return [];
              const filtered = filter(options, params);

              console.log(params.inputValue);
              filtered.push({
                inputValue: params.inputValue,
                firstname: `Add "${params.inputValue}"`,
                lastname: '',
              });

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={data}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              setEmail(option.email);
              return `${option.firstname} ${option.lastname}`;
            }}
            defaultValue={emailName}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderOption={(option) => `${option.firstname} ${option.lastname}`}
            renderInput={(params) => (
              <TextField
                {...params}
                // value={email}
                margin="normal"
                className={classes.addInviteTextbox}
                placeholder="Ajouter nom ou courriel"
                onKeyUp={(e) => {
                  if (e.which === 13) {
                    setEmailName('');
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (re.test(String(email).toLowerCase())) {
                      dispatch(
                        updateScheduleInvitees({
                          scheduleId,
                          newInvite: email,
                        }),
                      );
                    } else { console.log('Invalid Email Address'); }
                  }
                }}
              />
            )}
          />
        </div>
        <div style={{ display: 'flex', flex: 0.2 }} />
      </div>
      <div className={classes.body} id="body">
        {renderInviteList()}
      </div>
    </div>
  );
}

export default InvitedList;
