import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100vh',
    background: '#474747',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#DAE6DC',
    },
  },
  sideToolbarContainer: {
    backgroundColor: 'red !important',
  },

  workout: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid solid',
    // overflow: 'hidden',
  },
  menuBar: {
    display: 'flex',
    flex: 0.1,
    width: '100%',
    height: '100%',
  },
  participantsDiv: {
    display: 'flex',
    flex: 0.5,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  // scrollBox: {
  //   display: 'flex',
  //   width: '100%',
  //   height: '100%',
  //   '& .slick-slider': {
  //     width: '70vw',
  //     '& .slick-slide': {
  //       filter: 'blur(1px) brightness(0.5)',
  //       pointerEvents: 'none',
  //       width: '39%',
  //       height: '100%',
  //       marginRight: '10px',
  //       backgroundColor: '#fff',
  //       '& img': {
  //         display: 'block',
  //         width: '100%',
  //         height: '100%',
  //         maxHeight: 380,
  //         minHeight: 320,
  //         objectFit: 'contain',
  //         // minWidth: 320,
  //       },
  //     },
  //     '& .slick-current': {
  //       filter: 'blur(0px)',
  //       pointerEvents: 'auto',
  //     },
  //   },
  // },
  innerSlide: {
    height: '100%',
    margin: 0,
    '& h3': {
      position: 'relative',
      margin: 0,
    },
  },
  slideCount: {
    display: 'block',
    fontSize: '1.5rem',
  },
  slideCheckbox: {
    transform: 'scale(2)',
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    '&:after': {
      backgroundColor: '#A4C40A',
    },
  },
  resultBox: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #2B2B2B',
    padding: '1em',
    columnGap: '1rem',
    '& img': {
      margin: '.5rem 0',
    },
  },
  resultBadge: {
    display: 'grid',
    placeItems: 'center',
    width: '20%',
    color: '#fff',
    fontWeight: '700',
  },
  resultExercisField: {
    flex: 1,
    fontWeight: '600',
    fontSize: '1rem',
  },
  resultExerciseBox: {
    margin: 0,
  },
  resultExerciseName: {
    margin: 0,
    width: '100%',
  },
  resultHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    '& h5': {
      margin: '0 .4rem',
      fontWeight: '700',
      fontSize: '20px',
      color: '#d8e6dc',
    },
  },
  singleExerciseDiv: {
    display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '2em',
  },
  singleExerciseDivEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '250px',
    marginTop: '45%',
  },
  singleExerciseDivText: {
    marginTop: 10,
    // transform: 'translateY(-50%)',
  },
  resultsContainer: {
    height: '100%',
    width: '30vw',
    backgroundColor: '#626265',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  exercisesFieldContainer: {
    display: 'flex',
    height: 'calc(55vh - 4.2em)',
    overflow: 'hidden',
    marginTop: '4.2em',
    boxSizing: 'border-box',
    columnGap: '1em',
    [theme.breakpoints.down('md')]: {
      height: 'calc(50vh - 4.2em)',
    },
  },
  exercisesField: {
    flex: 1,
    height: '100%',
    backgroundColor: '#626265',
  },
  slider: {
    width: '70vw',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .slick-current': {
      filter: 'blur(0px)',
      pointerEvents: 'auto',
    },
    '& .slick-list': {
      margin: '0 0.75em',
      [theme.breakpoints.down('md')]: {
        margin: '0 0.25em',
      },
    },
    '& .slick-slide>div': {
      padding: '0px 0.75em',
      [theme.breakpoints.down('md')]: {
        margin: '0 0.25em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  ExcersiceCardWrapper: {
    textAlign: 'center',
  },
  ExcersiceCardContainer: {
    position: 'relative',
    height: '43vh',
    width: '100%',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: '40vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
  },
  ExcersiceCardContainerHidden: {
    pointerEvents: 'none',
    backgroundColor: '#FFF',
    filter: 'blur(0.5px) brightness(0.5)',
  },
  ExcersiceCardHeader: {
    position: 'relative',
    width: '100%',
    padding: '0.25em 0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  excersiceCardDescription: {
    position: 'relative',
    width: '100%',
    minHeight: '3em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
    borderBox: 'box-sizing',
    '& p': {
      padding: 0,
      margin: 0,
    },
    '& > p:nth-of-type(1)': {
      fontSize: '0.75rem',
      fontWeight: '700',
      color: '#2B2B2B',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.625rem',
      },
    },
    '& > p:nth-of-type(2)': {
      fontSize: '1rem',
      color: '#2B2B2B',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
  },
  aboutButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translateX(-25%)',
    [theme.breakpoints.down('md')]: {
      '& svg': {
        fontSize: '1.5rem',
      },
    },
  },
  excersiceCardView: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  excersiceCardVideo: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objcertFit: 'cover',
  },
  excersiceCardImage: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '& > img': {
      width: '100%',
      objectFit: 'cover',
    },
  },
  liveVideoContainer: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
  },
}));
