import moment from 'moment';

export default [
  // {
  //   date: moment().format('DD/MM/YYYY'),
  //   data: {
  //     name: 'Belly Jelly',
  //     id: '1010',
  //     Image: 'https://s3.amazonaws.com/prod.skimble/assets/4028/skimble-workout-trainer-exercise-four-square-hops-5_iphone.jpg',
  //     DayWiseSelection: {
  //       info: {
  //         Name: 'Delhi Belly',
  //         Image: 'https://s.abcnews.com/images/GMA/140722_gma_newsies1_16x9_992.jpg',
  //       },
  //       name: 'Day2',
  //     },
  //     time: '2:39 am',
  //     members: '',
  //     live: false,
  //   },
  // },
];
