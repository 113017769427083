import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import resultBadge from '../../../assets/resultBadge.png';
import useStyles from '../css/VideoChatStyles';

const Patricipants = ({ participants }) => {
  const classes = useStyles();
  const sortedparticipants = useMemo(
    () => participants.sort((a, b) => b.exercise - a.exercise),
    [participants],
  );
  return (
    <>
      {sortedparticipants.map((e, index) => (
        <div className={classes.resultBox}>
          <span className={classes.resultBadge}>
            {index === 0 ? (
              <img
                style={{ height: 25, width: 25 }}
                alt="result"
                src={`${resultBadge}`}
              />
            ) : <>{index + 1}</>}
          </span>

          <div className={classes.resultExercisField}>
            <p className={classes.resultExerciseBox}>
              {e.exercise >= 100 ? 'Terminé' : `Exercise ${e.exercise}`}
            </p>
            <p className={classes.resultExerciseName}>{e.name}</p>
          </div>
          {/* <img width="45" src={image} alt="slide" /> */}
        </div>
      ))}
    </>
  );
};

Patricipants.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Patricipants;
