import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from '../../../config/baseUrl';

const token = localStorage.getItem('token');
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const fetchProgramss = (pageData) => {
  axios.get(`${baseUrl}/programs?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search}`, config);
  // .then((res) => console.log('res', res))
  // .catch((err) => console.log('err'));
};

export const fetchExercise = (data) => new Promise((resolve, reject) => {
  console.log('config', config, data);
  axios.post(`${baseUrl}/programs/getexercise`, data, config)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fetchExerciseById = createAsyncThunk('scheduler/fetchExerciseById', async (userData, { rejectWithValue }) => {
  try {
    const response = await fetchExercise(userData);

    return response.data;
  } catch (err) {
    const error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    // We got validation errors, let's return those so we can reference
    // in our component and set form errors
    return rejectWithValue(error.response.statusText);
  }
});
