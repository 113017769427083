/* eslint-disable indent */
const throttle = (func, wait) => {
    let timeout;
    // eslint-disable-next-line func-names
    return function (...args) {
        const context = this;
        const later = () => {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export default throttle;
