/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

// importing icons and material ui components
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// importing actions
import {
  chooseProgram, selectPrograms, fetchPrograms, setPrograms,
} from '../Slice/ProgramSlice';

// importing style
import useStyles from '../css/ProgramStyles';

function Programs() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const programs = useSelector(selectPrograms);
  // console.log('programs', programs);
  const dispatch = useDispatch();
  const [loadMore, setLoadMore] = React.useState(false);
  const [Page, setPage] = React.useState(1);
  const [searchData, setSearchData] = React.useState('');
  const fetchAsyncPrograms = async (pageData) => {
    console.log('page', pageData);
    await dispatch(fetchPrograms(pageData))
      .then(unwrapResult)
      .then((res) => {
        // console.log('ress', ...res);
        dispatch(setPrograms([...res]));
        setLoadMore(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    if (loadMore) {
      fetchAsyncPrograms({ page: Page + 1, limit: 10, search: searchData });
      setPage((prevState) => ({ Page: prevState.Page + 1 }));
      // fetchAsyncPrograms()
    }
  }, [loadMore]);

  useEffect(() => {
    const list = document.getElementById('body');
    list.addEventListener('scroll', (e) => {
      const el = e.target;
      if (el.scrollTop + el.clientHeight === el.scrollHeight) {
        setLoadMore(true);
        // fetchAsyncPrograms({ page: Page + 1, limit: 10 });
      }
    });
    fetchAsyncPrograms({ page: 1, limit: 10, search: searchData });
    return () => { list.removeEventListener('scroll', () => { console.log('listener removed'); }); };
  }, []);
  const handleSearch = (e) => {
    setSearchData(e.target.value);
    fetchAsyncPrograms({ page: Page, limit: 10, search: e.target.value });
    // console.log(searchData);
  };
  const renderPrograms = () => programs.map((prog, index) => (
    <button
      id={prog._id.toString()}
      style={{
        border: 'none', outline: 'none', background: 'transparent', margin: '10px',
      }}
      className={`button-hover ${classes.program}`}
      onClick={() => {
        dispatch(chooseProgram(prog));
        history.push({
          pathname: `/programs/${prog._id}`,
          // state: { id: location.state.id === undefined ? null : location.state.id },
        });
      }}
    >
      <div id={prog._id.toString()} className={classes.program}>
        <div className={classes.imageContainer}>
          <img src={`https://studiogym.com/upload/${prog.photo}`} alt="program" className={classes.workoutimage} />
        </div>
        <div className={classes.descriptionContainer}>
          {prog.description}
        </div>
      </div>
    </button>
  ));

  return (
    <div className={classes.root}>
      <LinearProgress style={{ width: '100%', opacity: loadMore ? 1 : 0 }} />

      <div className={classes.navBar}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <ArrowBackIosIcon
            color="black"
            fontSize="large"
            onClick={() => {
              sessionStorage.removeItem('WorkoutId');
              history.goBack();
            }}
          />
        </div>
        <div style={{
          display: 'flex', fontSize: '1.3rem', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <input
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              outline: 'none',
            }}
            placeholder="Recherche de programme"
            onChange={handleSearch}
          />
        </div>
        <div style={{ display: 'flex', flex: 0.2 }} />
      </div>
      <div className={classes.body} id="body">
        {renderPrograms()}
      </div>
    </div>
  );
}

export default Programs;
