import { hot } from 'react-hot-loader/root';
import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, useLocation,
} from 'react-router-dom';

import history from './config/history';

// importing screens
import Home from './app/home/screens/home';
import Programs from './features/programs/screens/programs';
import SingleProgram from './features/programs/screens/singleProgram';
import VideoChat from './features/Video/screens/VideoChat';
import Header from './UI/header/header';

import './App.css';
import InvitedList from './features/Invite/screens/InvitedList';
import Token from './UI/Token/Token';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

function App() {
  return (
    <Router history={history}>
      <Header />
      <Switch>
        {/* <div className="App"> */}
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/programs">
          <Programs />
        </Route>
        <Route exact path="/programs/:programid">
          <SingleProgram />
        </Route>
        <Route exact path="/liveWorkout/:scheduleid/:username">
          <VideoChat />
        </Route>
        <Route exact path="/liveWorkout/:scheduleid">
          <VideoChat />
        </Route>
        <Route exact path="/inviteList">
          <InvitedList />
        </Route>
        <Route exact path="/token">
          <Token />
        </Route>
        {/* </div> */}
      </Switch>
    </Router>
  );
}

export default hot(App);
