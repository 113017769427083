/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { useId } from 'react-id-generator';
import _ from 'lodash';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

// importing UI components
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Divider, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LazyImageLoad from '../helpers/LazImageLoad';

// importing styles
import useStyles from '../css/ProgramStyles';

import {
  selectProgram,
  chooseProgramVariant,
  fetchPrograms,
  modifyProgramVariantWithImages,
} from '../Slice/ProgramSlice';
import {
  addSchedule,
  selectDate,
  setLoading,
  deleteSchedule,
  CreateSchedule,
  setError,
  resetReadyForApiCall,
} from '../../Scehduler/Slice/ScheduleSlice';
import { fetchExercise } from '../api/ProgramApi';

import baseUrl from '../../../config/baseUrl';

function SingleProgram(props) {
  const { selectedProgram } = props;
  const classes = useStyles();
  const history = useHistory();
  // const location = useLocation();
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = React.useState('Select workout');
  const [loadingImages, setLoadingImages] = React.useState(false);
  const [addedImages, setAddedImages] = React.useState(false);
  const [data, setData] = React.useState({});
  const [exerciseLoading, setExerciseLoading] = React.useState(false);
  const { selectedDate } = useSelector((state) => state.scheduler);
  const _id = sessionStorage.getItem('WorkoutId');
  const deleteWorkout = () => {
    if (_id !== null) {
      console.log('success', _id);
      dispatch(
        deleteSchedule({
          _id,
        }),
      );
      sessionStorage.removeItem('WorkoutId');
    }
  };
  const createSchedule = async () => {
    dispatch(setLoading(true));
    console.log(selectedDate);
    const _date = new Date(localStorage.getItem('SELECTED_DATE'));
    const time = new Date(_date);
    const now = new Date();
    time.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    const scheduleData = {
      time: time.toISOString(),
      date: _date.toISOString(),
      week: 0,
      day: selectedProgram.DayWiseSelection.index,
      program: selectedProgram._id,
    };
    await dispatch(CreateSchedule(scheduleData))
      .then(unwrapResult)
      .then((res) => {
        dispatch(
          addSchedule(res.data),
        );
        dispatch(setLoading(false));
      })
      .catch((rejectedValueOrSerializedError) => {
        dispatch(setLoading(false));
        dispatch(setError(rejectedValueOrSerializedError));
        console.log(rejectedValueOrSerializedError);
      });
    dispatch(resetReadyForApiCall());
    history.push('/');
  };
  const fetchSourceAndReplace = async (items, promises, item) => {
    let allResponses = {};
    await Promise.all(promises)
      .then(
        axios.spread((...res) => {
          _.map(res, (resp) => {
            const t = {
              ...allResponses,
              [resp.data.data._id]: { data: resp.data.data },
            };
            allResponses = t;
          });
          // allResponses.push({ [res.data.data._id]: { data: res.data.data } });
        }),
      )
      .catch((err) => {
        console.log(err);
      });
    // map through the DayWiseExercises and add sources
    let t = item;
    const m = _.map(t, (value) => ({
      ...value,
      exercise_items: _.map(value.exercise_items, (val) => ({
        ...val,
        exercise: {
          ...val.exercise,
          image: allResponses[val.exercise.$id].data.other_path,
          video: allResponses[val.exercise.$id].data.video,
        },
      })),
    }));
    // const isVideo = m.exercise_items[0].map((val) => val);
    // console.log('video',);
    dispatch(modifyProgramVariantWithImages(m));
    const ScheduleData = {
      ...selectedProgram,
      DayWiseSelection: {
        ...selectedProgram.DayWiseSelection,
        value: {
          ...selectedProgram.DayWiseSelection.value,
          exercise_lists: m,
        },
      },
    };
    setExerciseLoading(false);
    setLoadingImages(false);
    setAddedImages(true);
  };
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchSources = async (item) => {
    const arrayWithImageSources = [];
    const promises = [];
    // Fetched all the exercises that need displaying
    await _.map(item, async (value) => {
      // fetched all the exercises inside one block
      await _.map(value.exercise_items, async (val) => {
        // create an array with all sources
        promises.push(
          axios.post(`${baseUrl}/programs/getexercise`, {
            id: val.exercise.$id,
          }, config),
        );

        arrayWithImageSources.push({
          [val.exercise.$id]: { id: val.exercise.$id },
        });
      });
    });
    // fetch sources for all items and replace
    await fetchSourceAndReplace(arrayWithImageSources, promises, item);
  };

  useEffect(() => {
    if (loadingImages) {
      if (data && data.exercise_lists) {
        fetchSources(data.exercise_lists);
      }
      setExerciseLoading(true);
      setLoadingImages(false);
    }
  }, [loadingImages]);
  const renderWorkout = () => {
    if (exerciseLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    if (addedImages && !loadingImages) {
      if (
        selectedProgram !== null
        && selectedProgram.DayWiseSelection
        && selectedProgram.DayWiseSelection !== null
      ) {
        // disptributing array over a 2X2 array for loading row wise data
        const target = [];
        const splicedArray = [];
        Object.assign(
          target,
          selectedProgram.DayWiseSelection.value.exercise_lists,
        );
        while (target.length > 0) {
          splicedArray.push(target.splice(0, 2));
        }
        return _.map(splicedArray, (value) => (
          <div
            className="responsive-card"
            style={{
              display: 'flex',
              // minHeight: 420,
              width: '100% ',
              fontWeight: '500',
            }}
          >
            {_.map(value, (val) => (
              // <div className="img-box">
              <>
                <div
                  className={`${val.exercise_items.length === 1 ? 'imageExerciseSingal' : 'imageExercise'} ${val.exercise_items.length > 0 ? classes.singleExerciseDiv : classes.singleExerciseDivEmpty}`}
                  style={{
                    backgroundColor: 'white',
                    margin: '5px',
                    minWidth: '50%',
                    textAlign: 'center',
                    height: '50% !important',
                    width: '50% !important',
                    // flexDirection: 'row',
                    // display: 'grid',
                    // gridTemplateColumns: 'repeat(2,1fr)',
                  }}
                >
                  <div style={val.exercise_items.length === 1 ? {
                    marginTop: '50px',
                  } : {
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                  >
                    {_.map(val.exercise_items, (exerciseValue) => (
                      exerciseValue.exercise.video !== undefined ? (
                        <div data-vjs-player>
                          <video
                            muted
                            loop
                            playsInline
                            // controls
                            autoPlay
                            style={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                              // margin: '0 auto',
                              // height: '100% !important',
                              // width: '100% !important',
                            }}
                            // src={`https://studiogym.com/upload/${exerciseValue.exercise.video}`}
                            alt="workout"
                            type="video/mp4"
                          >
                            <source src={`https://studiogym.com/upload/${exerciseValue.exercise.video}`} type="video/mp4" />
                          </video>
                        </div>
                      ) : (
                        <img
                          style={{
                            display: 'block',
                            margin: '0 auto',
                            width: 'auto',
                            height: '50%',
                            // height: '100% !important',
                            // width: '100% !important',
                          }}
                          src={`https://studiogym.com/upload/${exerciseValue.exercise.image}`}
                          alt="workout"
                        />
                      )
                    ))}
                  </div>
                  <div
                    className={` description-text ${val.exercise_items.length > 0
                      ? classes.singleExerciseDivText : null}`}
                    style={{ width: '100%', fontSize: '18px', marginTop: '5px' }}
                  >
                    {val.text}
                    {val.exercise_items.length && val.exercise_items[0].series !== '' && val.exercise_items[0].repetition_from !== ''
                      ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                          <Typography className="description-text" style={{ color: '#939393', marginRight: '5px', fontSize: '18px' }}> Sets </Typography>
                          <Typography className="description-text" style={{ fontSize: '18px' }}>
                            {val.exercise_items.length ? val.exercise_items[0].series : ''}
                          </Typography>
                          <Divider />
                          <hr />
                          <Typography className="description-text" style={{ color: '#939393', marginRight: '5px', fontSize: '18px' }}> Repetitions</Typography>
                          <Typography className="description-text" style={{ fontSize: '18px' }}>
                            {val.exercise_items.length ? val.exercise_items[0].repetition_from : ''}
                          </Typography>
                        </div>
                      ) : null}
                  </div>
                </div>
              </>
            ))}
          </div>
        ));
      }
    }
    return <div />;
  };

  return (
    <div className={classes.dialogRoot}>
      <div className={classes.navBar}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIosIcon
            color="black"
            fontSize="large"
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <Button
            variant="outlined"
            disabled={!addedImages}
            onClick={() => {
              deleteWorkout();
              createSchedule();
            }}
          >
            Confirm
          </Button>
        </div>
        <div style={{ display: 'flex', flex: 0.2 }} />
      </div>
      <div className={classes.programDetail}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={`https://studiogym.com/upload/${selectedProgram.photo}`}
            alt="workout"
            className={classes.workoutimage}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0.7,
            alignItems: 'center',
            // justifyContent: 'center',
            margin: '10px',
            width: '90%',
          }}
        >
          {selectedProgram.description}
        </div>
      </div>
      <div className={classes.selector}>
        <Select
          disabled={loadingImages}
          labelId="duration"
          id="duration"
          defaultValue="select"
          // value={selectedDay}
          onChange={(e) => {
            setSelectedDay(`Day${e.target.value}`);
            dispatch(chooseProgramVariant({ index: e.target.value, value: data }));
            setExerciseLoading(true);
            setLoadingImages(true);
            setAddedImages(false);
          }}
          className={classes.selectBox}
          disableUnderline
        >
          <MenuItem value="select" disabled>
            Select Day
          </MenuItem>
          {_.map(selectedProgram.weeks[0].days, (value, index) => (
            <MenuItem value={index + 1} onClick={() => setData(value)}>
              Day
              {' '}
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="program" aria-hidden="true" style={{ marginTop: '1rem', width: '70%' }}>
        <div className={`program-image ${classes.programImage}`}>{renderWorkout()}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ programs }) => {
  const { selectedProgram } = programs;
  return { selectedProgram };
};
export default connect(mapStateToProps, null)(SingleProgram);
