import React from 'react';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {
  Divider,
  List,
  ListItem,
} from '@material-ui/core';

const ListItems = () => (
  <div className="list" style={{ width: '300px' }}>
    <List style={{ paddingLeft: '30px', paddingTop: '15px' }}>
      <Typography
        style={{ fontWeight: '600', paddingLeft: '16px', paddingTop: '0px' }}
      >
        PROGRAM
      </Typography>
      <ListItem button>Search Program</ListItem>
      <ListItem button>Create Program</ListItem>
      <Typography
        style={{ fontWeight: '600', paddingLeft: '16px', paddingTop: '10px' }}
      >
        EXERCISE
      </Typography>
      <ListItem button>Search Exercises</ListItem>
      <ListItem button>Add Exercise</ListItem>
      <Typography
        style={{ fontWeight: '600', paddingLeft: '16px', paddingTop: '10px' }}
      >
        PROFILE
      </Typography>
      <ListItem button>Free Profile</ListItem>
      <ListItem button>Upgrade to Member</ListItem>
      <ListItem button>Learn More About PREMIUM</ListItem>
      <Typography
        style={{ fontWeight: '600', paddingLeft: '16px', paddingTop: '10px' }}
      >
        ARTICLES
      </Typography>
      <ListItem button>Search Articles</ListItem>
      <Typography
        style={{ fontWeight: '600', paddingLeft: '16px', paddingTop: '10px' }}
      >
        ABOUT US
      </Typography>
      <ListItem button>Presentation</ListItem>
      <ListItem button>Contact</ListItem>
    </List>
    <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
      <Divider />
      <FacebookIcon style={{ fontSize: '45px', paddingLeft: '5px' }} />
      <TwitterIcon style={{ fontSize: '45px', paddingLeft: '5px' }} />
      <PinterestIcon style={{ fontSize: '45px', paddingLeft: '5px' }} />
      <InstagramIcon style={{ fontSize: '45px', paddingLeft: '5px' }} />
      <YouTubeIcon style={{ fontSize: '45px', paddingLeft: '5px' }} />
      <Divider />
    </div>
  </div>
);

export default ListItems;
