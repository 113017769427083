import React from 'react';
import { PropTypes } from 'prop-types';
import useStyles from '../css/VideoChatStyles';
import Participants from './Participants';
import resultCheckbox from '../../../assets/resultCheckbox.png';
import { ExcersiceCard } from './ExcerciseCard';

const ExercisesField = ({ exercises, passedExercise, participants }) => {
  const classes = useStyles();
  return (
    <div className={classes.exercisesFieldContainer}>
      <div className={classes.exercisesField}>
        {/* <ExcersiceVideo passedExercise={passedExercise} exercises={exercises} /> */}
        {exercises.length > 0 && (
        <ExcersiceCard
          passedExercise={passedExercise}
          exercises={exercises}
        />
        )}
      </div>

      <div className={classes.resultsContainer}>
        <div className={classes.resultHeader}>
          <img width="22" src={resultCheckbox} alt="" />
          <h5>RÉSULTATS</h5>
        </div>
        <Participants participants={participants} />
      </div>
    </div>
  );
};

ExercisesField.propTypes = {
  exercises: PropTypes.arrayOf(PropTypes.object).isRequired,
  passedExercise: PropTypes.arrayOf(PropTypes.object).isRequired,
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExercisesField;
