import React from 'react';
import PropTypes from 'prop-types';

export default class LazyImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    };
  }

  componentDidMount() {
    const img = new Image();
    const { src } = this.props;
    img.onload = () => {
      this.setState({
        loaded: true,
      });
    };
    img.onerror = () => {
      this.setState({
        error: true,
      });
    };
    img.src = src;
  }

  render() {
    const { error, loaded } = this.state;
    const {
      className, style, unloadedSrc, alt, src,
    } = this.props;
    if (error) {
      return (
        <img
          className={className}
          style={style}
          src={unloadedSrc}
          alt={alt}
        />
      );
    } if (!loaded) {
      return (
        <img
          className={className}
          style={style}
          src={unloadedSrc}
          alt={alt}
        />
      );
    }
    return (
      <img
        className={className}
        style={style}
        src={src}
        alt={alt}
      />
    );
  }
}

LazyImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.string,
  unloadedSrc: PropTypes.string,
  alt: PropTypes.string,

};
