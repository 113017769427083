/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable consistent-return */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// importing styles
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from './calendarStyles';

// importing Helpers
import { selectDate, setDate } from '../../features/Scehduler/Slice/ScheduleSlice';

// day identifier font size on mobile screen
const DayIdentifier = styled.div`
@media only screen and (max-device-width: 330px) {
  /* styles for mobile browsers smaller than 330px; (iPhone 5) */
  font-size: 5.5px;
 }

 @media only screen and (min-device-width: 331px) and (max-device-width: 800px) {
  font-size: 8px;
  }
 
  /* different techniques for iPad screening */
 @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  font-size: 11px;
  }
`;

function Calendar(props = {}) {
  const classes = useStyles();
  const { schedules } = props;
  const date = useSelector(selectDate);
  const prevYear = useSelector(selectDate);
  const dispatch = useDispatch();

  // const [date, setDate] = useState(moment());
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [currentData, setCurrentData] = useState([]);
  const [showNext, setShowNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleDates, setVisibleDates] = useState(null);
  const [, updateState] = React.useState();
  const [test, setTest] = React.useState();
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const [mounted, setMounted] = React.useState(false);
  const { programs } = useSelector((state) => state.programs);

  useEffect(() => {
    const currentMonth = moment(date).format('MM');
    const currentYear = moment(date).format('YYYY');
    const currentMonth1 = moment(date).format('MMM YYYY');
    setTest(currentMonth1);
    const data = schedules.filter((item) => {
      const _date = new Date(item.date).toISOString().split('-');
      return Number(_date[1]) === Number(currentMonth)
          && Number(_date[0]) === Number(currentYear);
    });
    setCurrentData(data);
  }, [date, month, year, schedules]);

  useEffect(() => {
    if (!mounted) {
      window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
    }
    setMounted(true);
  }, []);

  const weekdayShortName = () => {
    const weekdayShort = moment.weekdaysShort();
    return weekdayShort.map((day) => (
      <th key={day} className={classes.calendarcellhead}>
        {day.charAt(0)}
      </th>
    ));
  };

  const getFirstDayOfMonth = (dt) => {
    const firstDay = moment(dt).startOf('month').format('d');
    return firstDay;
  };

  const getAllDaysInMonth = (dt) => {
    const days = moment(dt).daysInMonth();
    return days;
  };

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const setPrevMonth = (dt) => {
    const m = moment(dt).subtract(1, 'months');
    props.setMonth(m);
    setMonth(m.format('MMMM'));
    setShowNext(true);
    const y = moment(m).format('YYYY');
    setYear(y);
  };
  const setPrevYear = (py) => {
    const m = moment(py).subtract(1, 'years');
    props.setYear(m);
    setYear(m.format('YYYY'));
  };
  const setNextYear = (py) => {
    const m = moment(py).add(1, 'years');
    props.setYear(m);
    setYear(m.format('YYYY'));
  };
  const setNextMonth = (dt) => {
    const m = moment(dt).add(1, 'months');
    console.log('zzz', m);
    props.setMonth(m);
    setMonth(m.format('MMMM'));
    const y = moment(m).format('YYYY');
    setYear(y);

    if (moment().format('YYYY-MM') === m.format('YYYY-MM')) {
      setShowNext(false);
    }
  };

  const renderMonth = () => (
    <div className={classes.MonthDiv}>
      <div className={classes.iconContainer}>
        <ArrowBackIosIcon style={{ color: 'black', width: '0.7em' }} onClick={() => { setPrevMonth(date); }} />
      </div>
      {/* {`${month.substr(0, 3)} ${year}`} */}
      {test}
      <div className={classes.iconContainer}>

        <ArrowForwardIosIcon style={{ color: 'black', width: '0.7em' }} onClick={() => { setNextMonth(date); }} />
      </div>
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '-5px',
          right: 0,
          left: 0,
        }}
      >
        <ExpandLessIcon fontSize="large" style={{ color: 'black' }} onClick={() => { setPrevMonth(date); }} />
      </div>
    </div>
  );

  const getGroupDates = (dt) => {
    setLoading(true);
    let sD = moment(dt).subtract(1, 'weeks').startOf('weeks');
    let eD = moment(dt).add(1, 'weeks').endOf('weeks');
    if (sD.year() === dt.year() && eD.year() === dt.year()) {
      // If all the dates fall in same year but different months
      if (sD.month() < dt.month()) {
        sD = dt.startOf('month');
      }
      if (eD.month() > dt.month()) {
        eD = dt.startOf('month');
      }
    } else if (sD.year() === dt.year() && eD.year() > dt.year()) {
      // If sD is in the same Year but end Date is not in the same year
      if (sD.month() < dt.month()) {
        sD = dt.startOf('month');
      }
      eD = dt.endOf('month');
    } else if (sD.year() !== dt.year() && eD.year() === dt.year()) {
      // If sD is in the same Year but end Date is not in the same year
      sD = dt.startOf('month');

      if (eD.month() > dt.month()) {
        eD = dt.startOf('month');
      }
    }
    return { sD, eD };
  };
  // console.log('Data11', currentData);

  // eslint-disable-next-line array-callback-return
  const renderEventIndex = (_date) => currentData.map((todaySch, id) => {
    // console.log('Data11', _date);
    // console.log('TODAYSCH', todaySch);
    const program = programs.find((_program) => _program._id === todaySch.program);
    if (todaySch.date
      && _date === Number(new Date(todaySch.date).toISOString().split('-')[2].split('T')[0])) {
      // console.log('data111', { color: program.cover_color, index: todaySch.day });
      return { color: program?.cover_color, index: todaySch.day };
    }
    return '';
  }).filter((item) => item.index !== '' && item.index !== undefined).slice(0, 2).map((item, index) => <DayIdentifier className={index === 0 ? classes.firstEvent : classes.secondEvent} style={{ backgroundColor: item.color }}>{`${innerWidth > 576 ? 'Day' : 'D'} ${item.index}`}</DayIdentifier>);
  // // eslint-disable-next-line array-callback-return
  // const renderEventIndex = (Date) => currentData.map((todaySch, id) => {
  //   if (todaySch.data
  //     && Date === Number(moment(todaySch.date).format('DD'))
  //     && todaySch.data.DayWiseSelection
  //     && todaySch.data.DayWiseSelection.index) {
  //     return todaySch.data.DayWiseSelection.index;
  //   }
  //   return '';
  // }).filter((item) => item !== '').slice(0, 2).map((item, index) => <p className={index === 0 ? classes.firstEvent : classes.secondEvent}>{`Day ${item}`}</p>);

  const renderCalendar = () => {
    const daysInMonth = [];
    const blanks = [];
    const ident = [];

    // eslint-disable-next-line array-callback-return
    // const isFirstWeekVisible = visibleDates.sD.subtract(1, 'weeks').endOf
    // ('weeks').format('d') <= (7 - getFirstDayOfMonth(date) - 1);
    // console.log(isFirstWeekVisible);
    for (let i = 0; i < getFirstDayOfMonth(date); i += 1) {
      blanks.push(<td className={classes.calendarcell} />);
    }
    for (let d = 1; d <= getAllDaysInMonth(date); d += 1) {
      daysInMonth.push(
        <td key={d} className={d === parseInt(moment(date).format('D'), 10) ? classes.currentdaycell : classes.calendarcell}>
          <button
            id="DayIdentifierButton"
            className={classes.invisbleButton}
            onClick={() => {
              props.setMonth(moment(date).set('date', d));
              props.setYear(moment(date).set('date', d));
            }}
          >
            {d}
            <br />
            <div style={{ height: 60 }}>
              {renderEventIndex(d)}
            </div>
          </button>
        </td>,
      );
    }

    const totalSlots = [...blanks, ...daysInMonth];
    const rows = [];
    let cells = [];
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows
        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) { // when end loop we add remain date
        if (cells.length < 7) {
          const len = cells.length;
          // add blanks at the end
          for (let j = 0; j < (7 - len); j += 1) {
            cells.push(<td className={classes.calendarcell} />);
          }
        }
        rows.push(cells);
      }
    });
    const finalDays = rows.map((d, i) => <tr className={classes.calItemRow}>{d}</tr>);
    return (
      <table style={{ display: 'flex', flex: 0.8, flexDirection: 'column' }}>
        <thead>
          <tr className={classes.calendarRow}>{weekdayShortName()}</tr>
        </thead>
        <tbody>{finalDays}</tbody>
      </table>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.calendarContainer}>
        <div className={`width-remove ${classes.calendar}`}>
          {renderMonth()}
          {loading ? <div>Loading ... </div> : renderCalendar()}
          <div
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: '-40px',
              right: 0,
              left: 0,
            }}
          >
            <ExpandMoreIcon fontSize="large" style={{ color: 'black' }} onClick={() => { setNextMonth(date); }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;

Calendar.propTypes = {
  schedules: PropTypes.instanceOf(Array),
  setMonth: PropTypes.func,
  setYear: PropTypes.func,
};
