import moment from 'moment';
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// importing APIs
import baseUrl from '../../../config/baseUrl';
import {
  fetchSchedulesApi, createScheduleApi, deleteScheduleApi,
  editScheduleApi, fetchFullSchedulesByIdApi,
} from '../api/ScheduleApi';

// importing temporary helpers
import schedules from '../constants';

const initialState = {
  readyForApiCall: false,
  schedule: schedules,
  currentDate: moment(),
  loading: false,
  error: null,
};

// exporting async thunks
export const fetchSchedules = createAsyncThunk('scheduler/fetchSchedules', async (userData, { rejectWithValue }) => {
  try {
    let { startDate, endDate } = userData;
    const { user } = userData;
    startDate = startDate.split('/').reverse().join('-');
    endDate = endDate.split('/').reverse().join('-');
    const response = await fetchSchedulesApi(user, startDate, endDate);
    return response.data;
  } catch (err) {
    const error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    // We got validation errors, let's return those so we can reference
    // in our component and set form errors
    return rejectWithValue(error.response.statusText);
  }
});

// exporting async thunks
export const fetchFullScheduleById = createAsyncThunk('scheduler/fetchScheduleById', async (userData, { rejectWithValue }) => {
  try {
    const { user, id } = userData;
    const response = await fetchFullSchedulesByIdApi(user, id);
    return response.data;
  } catch (err) {
    const error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    // We got validation errors, let's return those so we can reference
    // in our component and set form errors
    return rejectWithValue(error.response.statusText);
  }
});

export const CreateSchedule = createAsyncThunk('scheduler/createSchedule', async (data, { rejectWithValue }) => {
  try {
    if (!data.date) return null;

    data.date = data.date.split('/').reverse().join('-');
    const response = await createScheduleApi(data);
    return response.data;
  } catch (err) {
    const error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.statusText);
  }
});

export const EditSchedule = createAsyncThunk('scheduler/editSchedule', async (data, { rejectWithValue }) => {
  try {
    console.log('edit', data);
    const response = await editScheduleApi(data);
    return response.data;
  } catch (err) {
    const error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.statusText);
  }
});

export const DeleteSchedule = createAsyncThunk('scheduler/deleteSchedule', async (id, { rejectWithValue }) => {
  try {
    const response = await deleteScheduleApi(id.id);
    return response.id;
  } catch (err) {
    const error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.statusText);
  }
});
const scheduleSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.currentDate = action.payload;
    },
    setYear: (state, action) => {
      state.currentDate = action.payload;
    },
    addSchedule: (state, action) => {
      state.schedule.push(action.payload);
      state.readyForApiCall = true;
    },
    resetReadyForApiCall: (state, action) => {
      state.readyForApiCall = false;
    },
    updateScheduleTime: (state, action) => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const t = state.schedule.findIndex((sc) => sc._id === action.payload.id);
      if (t >= 0) {
        console.log('UPDATE_TO', action.payload.time);
        state.schedule[t].time = action.payload.time;

        EditSchedule(state.schedule[t]);
      }
    },
    updateScheduleInvitees: (state, action) => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const t = state.schedule.findIndex((sc) => sc._id === action.payload.scheduleId);
      if (t >= 0) {
        state.schedule[t].invited_members.push(action.payload.newInvite);
        EditSchedule(state.schedule[t]);
      }
    },
    deleteScheduleInvitees: (state, action) => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const t = state.schedule.findIndex((sc) => sc._id === action.payload.scheduleId);
      if (t >= 0) {
        // eslint-disable-next-line max-len
        state.schedule[t].invited_members.splice(action.payload.deleteIndex, 1);
        EditSchedule(state.schedule[t]);
      }
    },
    deleteSchedule: (state, action) => {
      console.log('Start', state.sche);
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const t = state.schedule.findIndex((sc) => sc._id === action.payload._id);
      console.log('T', t);
      if (t >= 0) {
        // eslint-disable-next-line max-len
        state.schedule.splice(t, t + 1);
        console.log('session ', state.schedule);
      }
    },
    updateLiveMode: (state, action) => {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      const t = state.schedule.findIndex((sc) => sc._id === action.payload._id);
      if (t >= 0) {
        state.schedule[t].live = action.payload.live;
      }
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
      state.loading = false;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    sendInvitation: (state, { payload }) => {
      console.log('payload for invitation', payload);
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios.post(`${baseUrl}/invite/sendInvitation`, payload, config);
    },
  },
});

// exporting actions
export const {
  setDate, setYear, addSchedule, updateScheduleTime, setSchedule, setLoading,
  setError, updateLiveMode, updateScheduleInvitees, deleteScheduleInvitees,
  deleteSchedule, sendInvitation, resetReadyForApiCall,
} = scheduleSlice.actions;

// exporting selectors
export const selectDate = (state) => state.scheduler.currentDate;
export const selectSchedule = (state) => state.scheduler.schedule;
export const schedulereducer = (state) => state.scheduler;

export default scheduleSlice.reducer;
