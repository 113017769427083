import React from 'react';

// importing components
import Scheduler from '../../../features/Scehduler/screens/Scheduler';

// importing css and resources
import '../css/home.css';

function Home() {
  return (
    <div className="root">
      <div className="">
        <Scheduler />
      </div>
    </div>
  );
}

export default Home;
