import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import useStyles from '../css/VideoChatStyles';
import { fetchExerciseById } from '../../programs/api/ProgramApi';
import './VideoChat.css';
import { fetchFullScheduleById } from '../../Scehduler/Slice/ScheduleSlice';
import ExercisesField from './ExercisesField';
import LiveWorkoutHeader from '../../../UI/header/LiveWorkoutHeader';
import { LiveVideoChat } from './LiveVideoChat';

const VideoChat = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const mounted = useRef(true);
  const { selectedProgram } = useSelector((state) => state.programs);
  const [exercises, setExercises] = useState([]);

  const { username = 'Utilisateur', scheduleid } = useParams();

  const loadExercise = async (id) => {
    try {
      const resultAcrion = await dispatch(fetchExerciseById({ id }));
      return unwrapResult(resultAcrion);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
      return null;
    }
  };

  const fetchScheduleId = async () => {
    await dispatch(fetchFullScheduleById({ id: scheduleid }))
      .then(unwrapResult)
      .then((res) => Promise.all(res.data.exercises.map((ex) => {
        if (ex.exercise_items.length > 0) {
          // eslint-disable-next-line array-callback-return
          return Promise.all(ex.exercise_items
            .map((item, _index) => loadExercise(item.exercise.$id).then((x) => {
              // eslint-disable-next-line no-underscore-dangle
              ex.exercise_items[_index].exercise = x;
            })));
        }
        return null;
      }).filter((e) => e))
        // eslint-disable-next-line max-len
        .then(() => setExercises(res.data.exercises.filter((e) => e.exercise_items.length)))).catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const [participants, setParticipants] = useState([{
    name: username,
    exercise: 1,
  }, {
    name: 'Jocelyn',
    exercise: 1,
  }, {
    name: 'Jordan',
    exercise: 1,
  }]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      fetchScheduleId();
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  const passedExercise = (count) => {
    const participant = participants.find((e) => e.name === 'Vincent');
    if (participant) {
      participant.exercise = count;
    }
    setParticipants(participants);
  };

  return (
    <>
      <LiveWorkoutHeader selectedProgram={selectedProgram} />
      <div className={classes.root}>
        <ExercisesField
          exercises={exercises}
          passedExercise={passedExercise}
          participants={participants}
        />

        <LiveVideoChat
          scheduleid={scheduleid}
          username={username}
        />
      </div>
    </>
  );
};
export default VideoChat;
