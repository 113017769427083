import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import axios from 'axios';
import baseUrl from '../../../config/baseUrl';

// importing helpers
import initialState from './initialState';
import { fetchProgramss } from '../api/ProgramApi';

export const fetchPrograms = createAsyncThunk('programs/fetchPrograms', async (pageData, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log('pageDaat', pageData);
    // const response = await fetchProgramss(pageData);
    const response = await axios.get(`${baseUrl}/programs?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search}`, config);
    // console.log('rESPONSE', response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);

    const err = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.statusText);
  }
});

const ProgramSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setPrograms: (state, action) => {
      state.programs = action.payload;
    },
    chooseProgram: (state, action) => {
      state.selectedProgram = action.payload;
    },
    chooseProgramVariant: (state, action) => {
      state.selectedProgram = { ...state.selectedProgram, DayWiseSelection: action.payload };
    },
    modifyProgramVariantWithImages: (state, action) => {
      state.selectedProgram = {
        ...state.selectedProgram,
        DayWiseSelection: {
          ...state.selectedProgram.DayWiseSelection,
          value: {
            ...state.selectedProgram.DayWiseSelection.value,
            exercise_lists: action.payload,
          },
        },
      };
    },
  },
});

// exporting custom actions
// export const addToSchedule = createAsyncThunk('programs/addToSchedule', async (state) => {
//   const selectedWorkout = state.programs.selectedProgram;
//   console.log(selectedWorkout);
//   await state.scheduler.schedule.push({
//     date: moment(state.scheduler.currentDate).format('DD/MM/YYYY'),
//     data: selectedWorkout,
//   });
// });

// exporting actions
export const {
  chooseProgram, chooseProgramVariant, setPrograms, modifyProgramVariantWithImages,
} = ProgramSlice.actions;
// exporting selectors
export const selectPrograms = (state) => state.programs.programs;
export const selectProgram = (state) => state.programs.selectedProgram;

export default ProgramSlice.reducer;
