import axios from 'axios';

import baseUrl from '../../../config/baseUrl';

const token = localStorage.getItem('token');
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const fetchSchedulesApi = (user, startDate, endDate) => axios.get(`${baseUrl}/schedule?startDate=${startDate}&endDate=${endDate}`, config);
export const fetchFullSchedulesByIdApi = (user, id) => axios.get(`${baseUrl}/schedule/fullSchedule?id=${id}`, config);

export const editScheduleApi = (data) => axios.post(`${baseUrl}/schedule/edit`, data, config);

export const createScheduleApi = (data) => axios.post(`${baseUrl}/schedule`, data, config);

export const deleteScheduleApi = (_id) => axios.delete(`${baseUrl}/schedule?id=${_id}`, config);
