import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    background: '#d8e6dc !important',
  },
  navBar: {
    display: 'flex',
    flex: 0.1,
    width: '90%',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    alignItems: 'center',
    // padding: '0 1rem',
    marginTop: '70px',
  },
  navBar1: {
    display: 'flex',
    flex: 0.2,
    width: '90%',
    flexDirection: 'row',

  },
  invitationName: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    fontSize: '1.2rem',
    fontWeight: 600,
    // fontWeight: 'bold',
  },
  imageContainer: {
    display: 'flex',
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileImage: {
    height: '50px',
    width: '50px',
  },
  invitemember: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  body: {
    display: 'flex',
    flex: 0.9,
    width: '90%',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginBottom: '20px',

  },
  addInviteTextbox: {
    width: '100%',
    border: 'none',
    background: 'none',
    outline: 'none',
  },
}));
