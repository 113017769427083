import { useEffect } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import baseUrl from '../../config/baseUrl';

export default function Token(props) {
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  console.log('TOken Code', code);
  const response = axios.get(`${baseUrl}/authCode?token=${code}`)
    .then((res) => {
      localStorage.setItem('token', res.data.body.access_token);
      history.push('/');
    })
    // eslint-disable-next-line no-alert
    .catch((err) => console.log('Something Went Wrong!!'));
  // console.log('response', response);
  return null;
}
