import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  body: {
    display: 'block',
    flex: 1,
    alignItems: 'center',
    background: '#d8e6dc !important',
    marginTop: 70,
    width: '100%',
    height: '100%',
    flexDirection: 'column',

  },
  header: {
    display: 'flex',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    // flex: 0.1,
    backgroundColor: 'white',
    width: '100%',
    flexDirection: 'row',
    minHeight: 'calc(var(--vh, 1vh) * 7)',
  },
  headerIcon: {
    display: 'flex',
    // flex: 0.15,
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    display: 'flex',
    flex: 0.85,
    justifyContent: 'flex-start',
    paddingLeft: '1rem',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyItems: 'flex-start',
  },
  calendar: {
    display: 'flex',
    // flex: 0.5,
    width: '100%',
    marginTop: '2rem',
  },
  agenda: {
    display: 'flex',
    // flex: 0.4,
    flexDirection: 'column',
    width: '100%',
    marginBottom: 'calc(var(--vh, 1vh) * 5)',
    marginTop: 'calc(var(--vh, 1vh) * 5)',

  },
  agendaActions: {
    display: 'flex',
    flex: 0.3,
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    marginBottom: '1rem',
  },
  dateSection: {
    textTransform: 'uppercase',
    display: 'flex',
    flex: 0.6,
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  actionSection: {
    display: 'flex',
    flex: 0.4,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  trainings: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflowY: 'scroll',
    maxHeight: '70vh',
  },
  dnone: {
    display: 'none',
  },
}));
