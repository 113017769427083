// create material ui theme
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1600,
    },
  },
});
export default theme;
