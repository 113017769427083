const copyToClipboard = (testToCopy) => {
  const aux = document.createElement('input');
  aux.setAttribute('value', testToCopy);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

export default copyToClipboard;
