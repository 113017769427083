import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../css/VideoChatStyles';
import throttle from '../../../../utils/throttle';

const LiveVideoChat = ({ username }) => {
  const videoRef = useRef(null);
  const clases = useStyles();
  const [jitsi, setJitsi] = useState({});

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement('script');
    script.src = 'https://8x8.vc/external_api.js';
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(false);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    const options = {
      roomName: 'SG First room',
      height: '100%',
      configOverwrite: {},
      interfaceConfigOverwrite: {
        TILE_VIEW_MAX_COLUMNS: 10,
        APP_NAME: 'Live Workout',
        BRAND_WATERMARK_LINK: 'https://studiogym.com',
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
          'fodeviceselection', 'hangup', 'profile', 'chat',
          'livestreaming', 'etherpad', 'sharedvideo', 'settings',
          'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
          'videobackgroundblur', 'download', 'help', 'mute-everyone',
        ],
      },
      userInfo: {
        email: 'email@studiogym.com',
        displayName: username,
      },
      parentNode: videoRef.current,
    };
    const pi = new window.JitsiMeetExternalAPI('8x8.vc', options);

    setJitsi(pi);
    pi?.executeCommand('toggleTileView');
  };

  const tooglechat = throttle(() => {
    const isMobile = window.innerWidth < 768;
    if (!isMobile) {
      if (jitsi?.executeCommand) {
        jitsi.executeCommand('toggleChat');
      }
    }
  }, 5000);

  useEffect(() => {
    initialiseJitsi();
    window.addEventListener('resize', tooglechat);
    return () => {
      jitsi?.dispose?.();
      window.removeEventListener('resize', tooglechat);
    };
  }, []);

  return (
    <div className={clases.liveVideoContainer}>
      <div style={{ width: '100%', height: '100%' }} ref={videoRef} />
    </div>
  );
};

LiveVideoChat.propTypes = {
  username: PropTypes.string.isRequired,
};

export default LiveVideoChat;
