import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import counterReducer from '../../features/counter/counterSlice';
import scheduleReducer from '../../features/Scehduler/Slice/ScheduleSlice';
import ProgramReducer from '../../features/programs/Slice/ProgramSlice';

const appReducer = combineReducers({
  counter: counterReducer,
  scheduler: scheduleReducer,
  programs: ProgramReducer,
});

// const persistConfig = {
//   key: 'studiogym',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, appReducer);

export default appReducer;
